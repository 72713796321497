.donate_content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-direction: column;
    color: #000;
}

.donate_header_align{
    text-align:center;
}

@media screen and (max-width: 768px) {
    .donate_header_align{
        text-align:center;
    }
    .donate_content{
        flex-direction:column;
    }
}