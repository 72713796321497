.account-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-top: 1em;
}

.loginuserprofile{
    margin-top: 16px;
    .multiSelectToggle{
        width: 100%;
        .MuiToggleButton-root{
            border: 1.2px solid #FF0000;
            border-radius: 5em;
            background: white;
            color: #FF0000;
            padding: 0.5em 2em;
        }
        .Mui-selected{
            background-color: #F86969 !important;
            color: white !important;
            font-weight: 900 !important;
        }

    }
    .responsivePosition{
        text-align:"left"
    }
    .responsiveDashboardText{
        font-size: 1em;
        font-style: italic;
        margin-top: 0.5em;
    }
    .Profilecount{
        background-color: #FFD4F1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 5rem;
        width: 5rem;
        max-width: 5rem;
        border-radius: 50%;
    }
    .responsive_text{
        font-size: 1.5rem;
    }

    .matchCount{
        color:#FFB955;
        font-size: 4rem;       
    }
    .base-container{
        padding-top: 5vh;
        position: relative;
    }
    .MuiTimeline-root{
        img{
            height: 80px;
            width: 80px;
            object-fit: scale-down;
        }
    }
    .imgclass{
        max-width: 50%;
        max-Height: 35%;
        img{
            height: 100%;
            width:  100%;
            object-fit: scale-down;
        }
    }
    .about_us_image{
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
    .hideMobile{
        // display: unset;
    }
    .onlyMobile{
        display: None;
    }
    .MuiPagination-ul{
        padding:5px 0;
        border:1px solid #f8696993;
        border-radius:10px
    }
    .MuiPaginationItem-root{
        color: #f8696993;
    }
    .MuiPaginationItem-previousNext{
        background-color: #ffb85584 !important;
        border-radius: 5em;
        font-weight: 900 !important;
    }
    .Mui-selected{
        background-color: #ffb85584 !important;
        color: #FF0000 !important;
        font-weight: 900 !important;
    }
    .desktopBorder{
        border: 1px solid black;
    }
    .reverseflex{
        flex-direction:row-reverse;
        background-color:#F0EBF5;
    }
    .bordercoloraccordion{
        border-color: #663399;
    }
    .bottommargin{
        margin-bottom: 1em;
    }
    @media only screen and (max-width: 1000px){
        .desktopBorder{
            border: unset;
        }
        .onlyMobile{
            display: unset;
        }
        .hideMobile{
            display: None;
        }
        .responsive_text{
            font-size: 2vh;
        }
        .matchCount{
            font-size: 2.3rem;   
        }
        .mobileMargin{
            margin: auto;
        }
        .floatingbuttons{
            position: -webkit-sticky;
            position: sticky;
            bottom: 1em;
        }
        .responsivePosition{
            text-align: center;
        }
        .Profilecount{
            height: 5rem;
            width: 5rem;
        }
        .responsiveDashboardText{
            font-size: 0.8em;
        }
    }
    .alternatesectioncolor{
        background-color:#F0EBF5 !important;
    }
    .tag{
        color: white;
        background-color: #C7B4DA;
        padding: 0.5rem 1.5rem;
        margin: 0.5em 0.5em;
    }
    .tag_alternate_color{
        background-color: #F4DFCD;
        color: black;
    }
}
.edit_buttons{
    width: 20%;
}
@media only screen and (max-width: 1000px){
    .account-top{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-top: 1em;
        // padding-top: "1em";
    }
    
    .edit_buttons{
        width: 100%;
    }
    .nobackgroudncolormobile{
        background-color: #f1f3f6 !important;
    }
}