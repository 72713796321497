.footer {
    background: linear-gradient(180deg, #663399 0%, #000000 100%);
    // height: 60vh;
    ul{
        margin:0px;
    }
    h6,li{
        color: white;
    }
    .MuiGrid-root{
        max-width: 100%;
    }
    .responsiveImg{        
        object-fit: contain;
        height: 100%;
    }    
    .hideDesktop{
        display: None;
    }
    .height30vh{
        height: 30vh;
    }
    @media screen and (max-width: 768px) {
        ul{
            list-style:none;
            padding: 0px;
            margin-top:24px
        }
        .height30vh{
            height: 20vh;
        }
        .hideMobile{
            display: none;
        }
        .photogrid {
            width: 100%;            
        }
        .responsiveImg{
            object-fit: scale-down ;
            height: 100%;
            width: 100%;
        }
        .MuiGrid-root{
            max-width: 100vw;
        }
        .hideDesktop{
            display: flex;
        }
    }
}
