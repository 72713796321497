.conversation_content{
  min-height: 300px;
  margin-top: 60px;

  .conversation_list {
    width: 100%;
    background-color: white;
    display: table;
  }
}
