body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Mui-selected:focus,
.Mui-selected:hover {
  // color: #fff !important
  background-color: rgba(67, 65, 158, 0.08) !important;
}

button:hover {
  background-color: #663399;
  border-color: #663399;
  // color:rgb(81, 95, 109);
  color: #fff
}
