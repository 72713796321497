.detailprofile{
    .multiSelectToggle{
        width: 100%;
        .MuiToggleButton-root{
            border: 1.2px solid #FF0000;
            border-radius: 5em;
            background: white;
            color: #FF0000;
            padding: 0.5em 2em;
        }
        .Mui-selected{
            background-color: #F86969 !important;
            color: white !important;
            font-weight: 900 !important;
        }

    }
    .responsive_text{
        font-size: 1.5rem;
    }
    .responsive_text_sub{
        font-size: 1em;
        margin-bottom: 5px;
    }
    .matchCount{
        color:#FFB955;
        font-size: 4rem;       
    }
    .base-container{
        padding-top: 5vh;
        position: relative;
        padding-bottom: 0px !important
    }
    .MuiTimeline-root{
        img{
            height: 80px;
            width: 80px;
            object-fit: scale-down;
        }
    }
    .imgclass{
        max-width: 50%;
        max-Height: 35%;
        img{
            height: 100%;
            width:  100%;
            object-fit: scale-down;
        }
    }
    .about_us_image{
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
    .hideMobile{
        // display: unset;
    }
    .onlyMobile{
        display: None;
    }
    .MuiPagination-ul{
        padding:5px 0;
        border:1px solid #f8696993;
        border-radius:10px
    }
    .MuiPaginationItem-root{
        color: #f8696993;
    }
    .MuiPaginationItem-previousNext{
        background-color: #ffb85584 !important;
        border-radius: 5em;
        font-weight: 900 !important;
    }
    .Mui-selected{
        background-color: #ffb85584 !important;
        color: #FF0000 !important;
        font-weight: 900 !important;
    }
    .desktopBorder{
        border: 1px solid rgba(102, 51, 153, 0.5);
    }
    .reverseflex{
        flex-direction:row-reverse;
        background-color:#F0EBF5;
    }
    .bordercoloraccordion{
        border-color: #663399;
    }
    .bottommargin{
        margin-bottom: 1em;
    }
    .view_all_image_button{
        position: unset;
    }
    @media only screen and (max-width: 1000px){
        .mobilerelative{
            position: relative;
        }
        .view_all_image_button{
            margin-top: 0.5em;
            left: 0;
            position: absolute;
            // bottom:-1em;
        }
        .mobile_margin{
            // margin-bottom: 4em;
        }
        .desktopBorder{
            border: unset;
        }
        .onlyMobile{
            display: unset;
        }
        .hideMobile{
            display: None;
        }
        .responsive_text{
            font-size: 2.4vh;
            text-transform: capitalize;
        }
        .responsive_text_sub{
            font-size: 1.9vh;
            margin-bottom: 3px;
        }
        .matchCount{
            font-size: 2.3rem;   
        }
        .mobileMargin{
            margin: auto;
        }
        .floatingbuttons{
            position: -webkit-sticky;
            position: sticky;
            bottom: 0em;
            display: flex;
            margin: 1em
        }
    }

}