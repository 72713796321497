.appbar {
  height: 4vh;
  .MuiPaper-root {
    z-index: 999 !important;
  }
  .width120{
    width:120px;
    
  }
  
  .width180{
    width: 180px;
  }
  @media only screen and (max-width: 800px) {
    height: 4vh;
    z-index: 999;
  }
}
