.App {
  text-align: center;
}
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media only screen and (min-width: 1000px) {
  .base-container {
    padding: 3rem calc(max(8%, env(safe-area-inset-left))) !important ;
  }
  .base-container-without-top {
    padding: 0 calc(max(8%, env(safe-area-inset-left))) !important ;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTypography-root {
  word-break: normal;
}

.MuiPagination-ul {
  padding: 5px 0;
  border: 1px solid #f8696993;
  border-radius: 10px;
  justify-content: center;
  position: relative;
}
.MuiPagination-ul li:first-child {
  position: absolute;
  left: 5px;
}
.MuiPagination-ul li:last-child {
  position: absolute;
  right: 5px;
}
.MuiPaginationItem-root {
  color: #f8696993;
}
.MuiPaginationItem-previousNext {
  background-color: #ffb85584 !important;
  border-radius: 5em;
  font-weight: 900 !important;
}
.Mui-selected {
  background-color: #ffb85584 !important;
  color: #ff0000 !important;
  font-weight: 900 !important;
}
