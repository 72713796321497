.mainbox{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
}
.forgetimg{
    width: 40%; 
    height: 100%; 
    margin-bottom: 50;
}
.modelforum{
    padding: 0 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
}
.responsive_width{
    width: 40%;
}
@media screen and (max-width: 768px) {
    .mainbox{
        display: grid;
        grid-template-rows: 40% 50%;
        justify-content: unset;
        width: 100%;
    }
    .forgetimg{
        width: 100%; 
        object-fit: contain;
        margin-bottom: 50;
    }
    .modelforum{
        width: 100%;
    }
    .responsive_width{
        width: 100%;
    }
}