.myinbox {
  margin-top: 16px;
  .multiSelectToggle {
    width: 100%;
    .MuiToggleButton-root {
      border: 1.2px solid #ff0000;
      border-radius: 5em;
      background: white;
      color: #ff0000;
      padding: 0.5em 2em;
    }
    .Mui-selected {
      background-color: #f86969 !important;
      color: white !important;
      font-weight: 900 !important;
    }
  }
  .inboxlist {
    margin: 0 13vw;
  }
  .toggleButtoncss{
    background-color: white;
    border: 1.2px solid #ff0000;
    color: #ff0000;
  }
  .toggleButtoncss:hover{
    background-color: #663399;
  }
  .outlinedButton {
    border-bottom: 1px solid #707070 !important;
  }
  .indexnotificationcount {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
  .indexnotificationcount:after {
    content: "";
    flex: 1 1;
    border-bottom: 2px solid #ffffff;
    margin: auto auto auto 1em;
  }

  .responsive_text {
    font-size: 1.5rem;
  }
  .matchCount {
    color: #ffb955;
    font-size: 4rem;
  }
  .maindivinbox {
    background-color: #ffe9f8;
    padding: 0.5rem;
    border-radius: 2rem;
    border: 1px solid black;
  }
  .gridbox {
    background-color: white;
    border: 1px solid #707070;
  }
  .base-container {
    padding-top: 5vh;
    position: relative;
  }
  .MuiTimeline-root {
    img {
      height: 80px;
      width: 80px;
      object-fit: scale-down;
    }
  }
  .imgclass {
    max-width: 50%;
    max-height: 35%;
    img {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
  }
  .about_us_image {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
  .onlyMobile {
    display: None;
  }
  .desktopBorder {
    border: 1px solid black;
  }
  .reverseflex {
    flex-direction: row-reverse;
    background-color: #f0ebf5;
  }
  .bordercoloraccordion {
    border-color: #663399;
  }
  .bottommargin {
    margin-bottom: 1em;
  }
  .responsiveBorderButton {
    border-color: "white";
  }
  .MuiTabs-flexContainer {
    button:hover {
      color: #fff;
    }
  }
  @media only screen and (max-width: 1000px) {

    .maindivinbox{
      background-color: #f1f3f6;
      border: none;
    }
    .indexnotificationcount:after{
      border-bottom: 2px solid #E5E5E5;
    }
    .desktopBorder {
      border: unset;
    }
    .onlyMobile {
      display: unset;
    }
    .hideMobile {
      display: None;
    }
    .responsive_text {
      font-size: 2vh;
    }
    .matchCount {
      font-size: 2.3rem;
    }
    .mobileMargin {
      margin: auto;
    }
    .floatingbuttons {
      position: -webkit-sticky;
      position: sticky;
      bottom: 1em;
    }
    .inboxlist {
      margin: 0;
    }
    .outlinedButton {
      border-color: white;
      border-right: 1px solid #707070 !important;
    }
  }
}
