.registration-layout {
    .content_container{
        position: absolute;
        background-color: white;
        border: 1px solid #707070;
        border-radius: 1rem;
        min-height: 80vh;
        width: 70vw;
        left: 4vw;
        top: 8vw;
    }
    .stepper_container{
        position: absolute;
        border-radius: 1rem;
        height: 80vh;
        width: 20vw;
        left: 75vw;
        top: 8vw;
    }
    .logo_mobile{
        position: absolute;
        left: 10vw;
        top: 5vh;
        height:1em;
        width:1em;
    }
    @media only screen and (max-width: 700px){
        .logo_mobile{
            position: static;
            text-align: center;
            align-items: center;
            background-color: #663399;
            height:6vh;
            width:100vw;
            display: flex;
        }    
        .content_container{
            position: static;
            border-radius: 0;
            border: 0;
            width: 100%;
        }
        .stepper_container{
            position: static;
            background-color: #fff8ee;
            border-radius: 0;
            width: 100%;
            height: 100%;
            .MuiStepper-root{
                height: 7em;
                padding-top: 1em;
                .rootstepper_grid{
                    display: grid;
                    grid-template-rows: auto auto;
                    grid-template-columns: auto;
                    grid-gap: 1em;
                    text-align: center;
                    .MuiStepLabel-iconContainer{
                        display: flow-root;
                    }
                    .mobileStepperFontSize{
                        font-size: 14px;
                        word-break: break-word;  
                    }
                }
            }
        }
    }
}
