.file-upload {
    background-color: #f9faff;
    width: 100%;
    margin: 0 auto;
    // display: flex;
    border-radius: 16px;
}

.file-upload-content {
    display: none;
    text-align: center;
}

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
    z-index: 100;
}

.image-upload-wrap {
    border: 2px dashed #b9cff0;
    border-radius: 16px;
    position: relative;
    flex: 1;
}

.img-border-error {
    border: 2px dashed #f44336;
}
.img-border-color {
    color: #f44336;
}

.image-title-wrap {
    padding: 0 15px 15px 15px;
    color: #7c7c7c;
}

.drag-text {
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    color: #7c7c7c;
    padding: 30px 0;
    // height: 150px;

    .MuiTypography-root {
        margin-top: 20px;
    }
}
.image-drag {
    padding: 0;
}
img.file-upload-image {
    min-height: 100px;
    height: 150px;
    width: 100%;
    margin: auto;
    border-radius: 16px;
    object-fit: contain;
}
