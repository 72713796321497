.LandingPage{
    .base-container{
        padding-top: 5vh;
        position: relative;
    }
    .MuiTimeline-root{
        img{
            height: 80px;
            width: 80px;
            object-fit: scale-down;
        }
    }
    .imgclass{
        max-width: 50%;
        max-Height: 35%;
        img{
            height: 100%;
            width:  100%;
            object-fit: scale-down;
        }
    }
    .about_us_image{
        height: 100%;
        width: 100%;
        object-fit: scale-down;
    }
    .welcome-msg{
        // background: white;
        margin-top:50vh;
        // width: 40vh;
    }
    .register_free_gradient{
        display: block;
        position: absolute;
        top: 8vh;
        right: 0;
        background: rgb(255,255,255);
        background: linear-gradient(321.53deg, rgba(255, 39, 183, 1) -16.92%, rgba(255, 124, 211, 1) -5.92%, rgba(255, 185, 232, 1) 19.15%, rgba(255, 255, 255, 1) 62.58%);
        width: 30%;
        padding: 1% 1% 1% 1%;
        
    }
    .hideMobile{
        display: unset;
    }
    .onlyMobile{
        display: None;
    }
    
    .love_card_gradient{
        margin: 2em 1em !important;
        // width: 30vw !important;
        // height: 15em !important;
        background: rgb(255,185,85);
        background: linear-gradient(146deg, rgba(255,185,85,1) 0%, rgba(255,124,211,1) 100%);
    }

    @media only screen and (max-width: 1000px){
        .welcome-msg{
            // background: white;
            // margin-top:50vh;
            // width: 40vh;
        }
        .register_free_gradient{
          display: None;
        }
        .onlyMobile{
            display: unset;
        }
        .hideMobile{
            display: None;
        }
        .love_card_gradient{
            margin: 2em 1em !important;
            width: 20em;
            height: 15em;
        }
        .responsivetext{
            font-size: 14px;
        }
        .featureCarousal {
            .__active {
              .imgslider {
                -webkit-transform: scale(1) !important; /* Saf3.1+, Chrome */
                -moz-transform: scale(1) !important; /* FF3+ */
                -ms-transform: scale(1) !important; /* IE9 */
                -o-transform: scale(1) !important; /* Opera 11+ */
                transform: scale(1) !important;
                transition: unset;
              }
            }
          }
    }

      .whitefont{
        color: white;
    }
    // .featureCarousal {
    //     .__active {
    //       .imgslider {
    //         -webkit-transform: scale(1.2); /* Saf3.1+, Chrome */
    //         -moz-transform: scale(1.2); /* FF3+ */
    //         -ms-transform: scale(1.2); /* IE9 */
    //         -o-transform: scale(1.2); /* Opera 11+ */
    //         transform: scale(1.2);
    //         transition: 1.5s;
    //         filter: progid:DXImageTransform.Microsoft.Matrix(M11=0.9999619230641713, M12=-0.008726535498373935, M21=0.008726535498373935, M22=0.9999619230641713,SizingMethod='auto expand'); /* IE6–IE9 */
    //       }
    //     }
    //   }
}