.mymatches {
  margin-top: 16px;
  .multiSelectToggle {
    width: 100%;
    .MuiToggleButton-root {
      border: 1.2px solid #ff0000;
      border-radius: 5em;
      background: white;
      color: #ff0000;
      padding: 0.5em 2em;
    }
    .Mui-selected {
      background-color: #f86969 !important;
      color: white !important;
      font-weight: 900 !important;
    }
  }
  .filterIndication {
    width: 100%;
    .MuiToggleButton-root {
      border: 1.2px solid #ffb955;
      border-radius: 5em;
      color: #000;
      font-weight: bold;
      padding: 0.5em 1em;
      text-transform: none;
      background-color: #f86969 !important;
      color: white !important;
      font-weight: 900 !important;
    }
  }
  .responsive_text {
    font-size: 1.8rem;
  }
  .matchCount {
    color: #ffb955;
    font-size: 4rem;
    text-align: right;
    margin-right: 8px;
  }
  .base-container {
    position: relative;
    padding: 2rem calc(max(8%, env(safe-area-inset-left))) !important ;
  }
  .MuiTimeline-root {
    img {
      height: 80px;
      width: 80px;
      object-fit: scale-down;
    }
  }
  .imgclass {
    max-width: 50%;
    max-height: 35%;
    img {
      height: 100%;
      width: 100%;
      object-fit: scale-down;
    }
  }
  .about_us_image {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
  }
  .hideMobile {
    // display: unset;
  }
  .onlyMobile {
    display: None;
  }
  @media only screen and (max-width: 1000px) {
    .base-container {
      padding-bottom: 0 !important;
      padding-top: 5vh !important;
    }
    .onlyMobile {
      display: unset;
    }
    .hideMobile {
      display: None;
    }
    .responsive_text {
      font-size: 2vh;
    }
    .matchCount {
      font-size: 2.3rem;
    }
    .mobileMargin {
      margin: auto;
    }
  }
}
