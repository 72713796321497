.RegistrationPage {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    146deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 185, 232, 1) 82%,
    rgba(255, 124, 211, 1) 89%,
    rgba(255, 39, 183, 1) 100%
  );
}

.registration-baisc-form {
}

.registration-advance-form {
  .form-space {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-flow: wrap;
  }
}
.register_verify{
  .custom_text_box{
    margin-bottom: 16px;
    // width: 45%
  }
  .registration_button_width{
    width: 30%;
  }
  @media only screen and (max-width: 800px) {
    .registration_button_width{
      width: 100%;
    }
  }
}
button:hover {
  background-color: #663399;
  border-color: #663399;
  color: #663399
}